import React, { createContext, useContext, useState, useEffect } from 'react'

export interface ConfigContextType {
  app: {
    name: string
    version: string
  }
  shopify: {
    shop: string
    store: string
  }
  api: {
    endpoint: string
  }
  flow: {
    env: string
    accessNode: string
    walletDiscovery: string
  }
}

const DEFAULT_CONFIG: ConfigContextType = {
  app: {
    name: 'IO Admin',
    version: '0.0.0',
  },
  shopify: {
    shop: 'https://shopify.com',
    store: 'myshopify.com',
  },
  api: {
    endpoint: 'https://stoplight.io/mocks/infiniteobjects/api:main/12724503',
  },
  flow: {
    env: 'testnet',
    accessNode: 'https://access-testnet.onflow.org',
    walletDiscovery: 'https://flow-wallet-testnet.blocto.app/authn',
  },
}

const ConfigContext = createContext<ConfigContextType>(DEFAULT_CONFIG)

export const ConfigProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState(DEFAULT_CONFIG)

  useEffect(() => {
    setConfig({
      app: {
        name: process.env.app.name,
        version: process.env.app.version,
      },
      shopify: {
        shop: process.env.shopify.shop,
        store: process.env.shopify.store,
      },
      api: {
        endpoint: process.env.api.endpoint,
      },
      flow: {
        env: process.env.flow.env,
        accessNode: process.env.flow.accessNode,
        walletDiscovery: process.env.flow.walletDiscovery,
      },
    })
  }, [])

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  )
}

export const useConfig = (): ConfigContextType => useContext(ConfigContext)
