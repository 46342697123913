import App from 'next/app'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { ConfigProvider } from '../lib/context/config'
import { AppProvider as PolarisProvider } from '@shopify/polaris'

import 'sanitize.css'
import '@shopify/polaris/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/global.scss'

import enTranslations from '@shopify/polaris/locales/en.json'

const theme = { colors: { primary: '#1B28F7' } }

class Application extends App {
  render(): JSX.Element {
    const { Component, pageProps } = this.props

    return (
      <ConfigProvider>
        <PolarisProvider i18n={enTranslations} theme={theme}>
          <main id='app-container'>
            <ToastContainer />
            <Component {...pageProps} />
          </main>
        </PolarisProvider>
      </ConfigProvider>
    )
  }
}

export default Application
